<template>
  <div>
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-body row">
          <div class="col-sm-6">
            <div class="card-header">
              <h5 class="card-title">Production Notes</h5>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="pull-right">
              <div class="btn-group">
                <p-button type="primary" round outline @click="resetTable()">Reset</p-button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body row">

          <Notes
            v-bind:id="userSelectedProduction"
            gridName= "productionNotesGrid"
            mode="production"
          ></Notes>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import * as CONSTS from '../../../consts/constants'
  import Notes from 'src/components/Maketime/Sales/Notes.vue'


  export default{
    components: {
      Notes
    },
    computed: {
      ...mapGetters(['userSelectedProduction','userSelectedProductionCcy']),
      productionSelected () {
        return this.$store.getters.userSelectedProduction
      }
    },
    methods:{

      resetTable: function() {
        this.$children[1].resetTable()
      }
    }
  }
</script>
<style lang="scss">

  .tablecell {
   font-size: 14px;
 }

</style>
